<template>
  <v-card v-if="isVisible" v-bind="$attrs">
    <v-system-bar>Schule</v-system-bar>
    <v-list subheader>
      <ListItem title="Telefon Schule">
        <PhoneNumber :value="info.phoneSchool" />
      </ListItem>
    </v-list>
  </v-card>
</template>

<script>
import { defineComponent } from "vue";
import ListItem from "@/components/ListItem.vue";
import PhoneNumber from "common/components/Person/PhoneNumber.vue";

export default defineComponent({
  name: "ContactSchool",
  components: {
    ListItem,
    PhoneNumber,
  },
  props: {
    person: { type: Object, default: null },
  },
  data() {
    return {
      info: null,
    };
  },
  computed: {
    hasPerson() {
      return this.person && this.person.id;
    },
    isVisible() {
      return (
        this.hasPerson &&
        this.info &&
        (this.person.type.code === "employee" ||
          this.person.type.code === "teacher")
      );
    },
  },
  watch: {
    person() {
      this.fetchData();
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.info = null;
      if (!this.hasPerson) return;

      this.info = await this.apiGet({
        resource: "person/schoolinfo",
        id: this.person.id,
      });
    },
  },
});
</script>
